<template>
    <div class="componentWrapper">
        <div class="nameWrapper">
            <img 
                class="logoImg"
                :src="require('@/assets/logo/mainLogo.svg')" 
                alt=""
            >
            <h2 class="name">Tahkuranna Maastikukaitse</h2>
        </div>
        <div class="contact">
            <div style="display: flex">
                <div class="single">
                    <img 
                        class="icon"
                        id="iconOne"
                        :src="require('@/assets/icons/phone.svg')" 
                        alt=""
                    >
                </div>
                <p>+372 5723 7548</p>
            </div>
            <div style="display: flex">
                <div class="single">
                    <img 
                        class="icon"
                        id="iconTwo"
                        :src="require('@/assets/icons/email.svg')" 
                        alt=""
                    >
                </div>
                <p>tahkurannamaastikukaitse@gmail.com</p>
            </div>
        </div>
        <div class="foot">
            <p>MTÜ Tahkuranna Maastikukaitse © {{new Date().getFullYear()}} - Kõik õigused kaitstud!</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'mainFooter',
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.componentWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0px auto;
    margin-bottom: 0px;
    border-radius: 0px;
    background: rgb(58, 82, 85);
    padding-top: 30px;
    padding-bottom: 20px;
}
.nameWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
}
.logoImg {
    height: 50px;
    margin-top: 55px;
    margin-left: 100px;
    margin-right: 20px;
}
.name {
    color: #dce3ee;
    margin: 0;
    margin-top: 63px;
    margin-left: 10px;
    font-size: 2rem;
    font-family: 'Quicksand', sans-serif;
}
.name:hover {
    color: #dce3ee;
    cursor: default;
}
.single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    border: 1px solid rgba(94, 126, 151, 0);
    background: rgba(66, 92, 112, 0);
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 10px;
    border-radius: 100%;
    height: 70px;
    width: 70px;
}
.contact {
    width: 30%;
    padding-left: 70px;
}
.contact p {
    margin: 0;
    margin-top: 33px;
    margin-left: 15px;
    font-size: 1.1rem;
    color: white;
    width: 300px;
    font-family: 'DM sans', sans-serif;
}
.icon {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}
#iconOne {
    width: 30px;
}
#iconTwo {
    width: 40px;
}
.foot {
    margin-top: 10px;
    width: 100%;
    text-align: center;
}
.foot p {
    width: 90%;
    padding-top: 20px;
    margin: 0;
    margin: 0 auto;
    color: white;
    font-size: .9rem;
    border-top: 1px solid rgb(120, 141, 151);
    font-family: 'DM sans', sans-serif;
}

@media(max-width: 1650px) {
    .contact {
        padding-left: 0px;
    }
}
@media(max-width: 1400px) {
    .illustrationBird {
        height: 100px;
        margin-top: 25px;
        margin-left: 80px;

    }
    .contact {
        padding-left: 0px;
        width: 40%;
    }
    .contact p {
        font-size: 1rem;
        margin-left: 0px;
    }
    #iconOne {
        width: 20px;
    }
    #iconTwo {
        width: 30px;
    }
    .nameWrapper {
        width: 60%;
    }
    .name {
        font-size: 1.5rem;
    }
}
@media (max-width: 1040px) {
    .nameWrapper {
        width: 100%;
    }
    .logoImg {
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .contact {
        width: 100%;
        padding-left: 87px;
    }
    .contact p {
        margin-top: 22px;
    }
    .single {
        margin: 0;
        margin-bottom: -20px;
    }
    .illustrationBird {
        height: 100px;
        margin-top: 0px;
        margin-left: 50px;
        margin-bottom: 20px;
    }
    .name {
        display: none;
    }
}
@media (max-width: 500px) {
    .contact {
        padding-left: 10px;
    }
    .illustrationBird {
        position: absolute;
        height: 70px;
        right: 20px;
        top: 65px;
    }
    .logoImg {
        margin-left: 22px;
    }
}
@media (max-width: 380px) {
    .contact {
        padding-left: 0px;
    }
    .illustrationBird {
        position: absolute;
        height: 70px;
        right: 20px;
        top: 65px;
    }
    .logoImg {
        margin-left: 12px;
    }
}
</style>
