var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"bar",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{staticClass:"illustrationBird",attrs:{"src":require('@/assets/illustrations/bird.svg'),"alt":""}}),_c('h3',{staticClass:"nameTitle"},[_vm._v("Tahkuranna Maastikukaitse")])]),_c('div',{staticClass:"logo"},[_c('img',{staticClass:"logoImg",attrs:{"src":require('@/assets/logo/mainLogo.svg'),"alt":""},on:{"click":function($event){return _vm.$router.push('/')}}}),_c('h2',[_vm._v("MTÜ Tahkuranna Maastikukaitse")])]),_vm._m(0),_c('div',{staticClass:"stats"},[_c('div',{staticClass:"singleStat"},[_c('img',{attrs:{"src":require('@/assets/icons/activity.svg'),"alt":""}}),_vm._m(1)]),_c('div',{staticClass:"singleStat"},[_c('img',{attrs:{"src":require('@/assets/icons/members.svg'),"alt":""}}),_vm._m(2)]),_c('div',{staticClass:"singleStat"},[_c('img',{attrs:{"src":require('@/assets/icons/court.svg'),"alt":""}}),_vm._m(3)]),_c('div',{staticClass:"singleStat"},[_c('img',{attrs:{"src":require('@/assets/icons/signature.svg'),"alt":""}}),_vm._m(4)]),_c('div',{staticClass:"singleStat"},[_c('img',{attrs:{"src":require('@/assets/icons/crowd.svg'),"alt":""}}),_vm._m(5)])]),_vm._m(6),_c('darkSection',{staticClass:"darkSection"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutText"},[_c('p',[_vm._v("Tahkuranna Maastikukaitse on ellu kutsutud, et seista hea meie piirkonna keskkonnaväärtuste säilimise ning heaolu eest."),_c('br'),_c('br'),_vm._v(" MTÜ on loodud vastulöögina algatusele avada Võiste II liivakarjäär, mille puhul plaanitakse liiva kaevandamist "),_c('b',[_vm._v("Uulu-Võiste maastikukaitseala")]),_vm._v(" ja "),_c('b',[_vm._v("Jõulumäe terviseradade")]),_vm._v(" vahetus läheduses. "),_c('br'),_c('br'),_c('b',{attrs:{"id":"project"}},[_vm._v("310")]),_vm._v(" kohalikku elanikku on andnud oma allkirja, näidates üles vastuseisu eraisiku algatusele muuta "),_c('b',[_vm._v("7ha")]),_vm._v(" suurune "),_c('b',[_vm._v("männimets")]),_vm._v(" liivakarjääriks.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("7 kuud")]),_vm._v(" MTÜ tegevust")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("10")]),_vm._v(" aktiivset "),_c('b',[_vm._v("liiget")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("2 kohtuvaiet")]),_vm._v(" - Võiste II karjääri metsateatis ja kaeveluba")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("310 allkirja")]),_vm._v(" karjääri vastu")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("87 osavõtjat")]),_vm._v(" avalikul arutelul")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"single"},[_c('h3',[_vm._v("MTÜ Tahkuranna Maastikukaitse")]),_c('p',[_vm._v("Pärnu maakond, Häädemeeste vald, Leina küla, Kaasiku, 86504")]),_c('p',[_vm._v("Reg. 80614207")]),_c('p',[_vm._v("EE847700771008199323 (LHV)")]),_c('p',{staticClass:"email"},[_vm._v("tahkurannamaastikukaitse@gmail.com")])]),_c('div',{staticClass:"single"},[_c('h3',[_vm._v("Juhatuse liige")]),_c('p',{staticStyle:{"font-size":"1.3rem"}},[_vm._v("Teet Suursild")]),_c('p',[_vm._v("+372 5723 7548")]),_c('p',{staticClass:"email"},[_vm._v("teetsuur@gmail.com")])])])
}]

export { render, staticRenderFns }