<template>
    <div class="componentWrapper">
        <div class="bar">
            <img 
                class="illustrationBird"
                :src="require('@/assets/illustrations/bird.svg')" 
                alt=""
            >
            <h3 class="nameTitle">Tahkuranna Maastikukaitse</h3>
        </div>
        <h2>MTÜ tegemistega seotud uudised</h2>
        <div class="posts">
            <div class="powerpoint">
                <h3>Avaliku arutelu esitlus</h3>
                <div class="imageGallery">
                    <img class="imagePP" @click="openLink('arutelu-esitlus')" v-for="(image, i) in images" :src="image" :key="i">
                </div>
                <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
            </div>
            <div class="singlePost" @click="openLink('ekspress')">
                <h3>Eesti Ekspress</h3>
                <div class="shareLink">
                    <div class="image">
                        <img 
                            :src="require('@/assets/images/eestiekspress.jpeg')" 
                            alt=""
                        >
                    </div>
                    <div class="linkFooter">
                        <p class="origin">EKSPRESS.DELFI.EE</p>
                        <p class="title">Kohalikud võitlevad karjääri ja keskonnaameti vastu: "Kaitsealusel maal jalutada ei tohi, aga kõrvale hiiglasliku augu kaevata võib?"</p>
                    </div>
                </div>
            </div>
            <div class="singlePost" @click="openLink('postimees')">
                <h3>Pärnu Postimees</h3>
                <div class="shareLink">
                    <div class="image">
                        <img 
                            :src="require('@/assets/images/postimees.jpeg')" 
                            alt=""
                        >
                    </div>
                    <div class="linkFooter">
                        <p class="origin">PARNU.POSTIMEES.EE</p>
                        <p class="title">Kohalikud jätkavad võitlust Jõulumäe metsa serva plaanitava karjääri vastu</p>
                    </div>
                </div>
            </div>
            <div class="singlePost" @click="openLink('ekspress2')">
                <div class="shareLink">
                    <div class="image">
                        <img 
                            :src="require('@/assets/images/ekspress2.jpg')" 
                            alt=""
                        >
                    </div>
                    <div class="linkFooter">
                        <p class="origin">EKSPRESS.DELFI.EE</p>
                        <p class="title">Huvilisi rohkem, kui saali mahub: Pärnumaale karjääri kaevata lubavad ametnikud andsid külarahvale aru</p>
                    </div>
                </div>
            </div>
            <div class="singlePost" @click="openLink('postimees2')">
                <div class="shareLink">
                    <div class="image">
                        <img 
                            :src="require('@/assets/images/postimees.jpg')" 
                            alt=""
                        >
                    </div>
                    <div class="linkFooter">
                        <p class="origin">PARNU.POSTIMEES.EE</p>
                        <p class="title">Võiste uue liivakarjääri juures kiskus kismaks</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
    name: 'contactPage',
    components: {
        VueGallerySlideshow
    },
    data() {
        return {
            images: [
                require('@/assets/documents/arutelu.png'),
            ],
            index: null
        }
    },
    methods: {
        openLink(source) {
            if(source == 'postimees') {
                window.open('https://parnu.postimees.ee/7634730/kohalikud-marus-armastatud-spordikoha-naabriks-plaanitakse-liivakarjaari?fbclid=IwAR2gJLN-NwZutJvmQ3mRTMnyIb0knEQ2Hdk_BVvKuMhfMBT9_t6jDSWLTjg', '_blank').focus()
            }
            if(source == 'ekspress') {
                window.open('https://ekspress.delfi.ee/artikkel/120047900/jalle-salastatud-maaomanik-ei-tea-miks-kohalikud-on-tema-karjaariplaanide-vastu-sest-keskkonnaamet-on-vastused-salastanud?fbclid=IwAR0SqT7o37txNDO07qix12UJ1nGYEjvLLmAu7CjOjGk7ongNIA-3SVs2Mp0', '_blank').focus()
            }
            if(source == 'postimees2') {
                window.open('https://parnu.postimees.ee/7728611/voiste-uue-liivakarjaari-juures-kiskus-kismaks', '_blank').focus()
            }
            if(source == 'ekspress2') {
                window.open('https://ekspress.delfi.ee/artikkel/120098374/reportaaz-huvilisi-rohkem-kui-saali-mahub-parnumaale-karjaari-kaevata-lubavad-ametnikud-andsid-kularahvale-aru', '_blank').focus()
            }
            if(source == 'arutelu-esitlus') {
                window.open("/files/Avalik-arutelu-esitlus.pdf", '_blank');
            }
        }
    },

    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Uudised',
        })
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.componentWrapper {
    position: relative;
    padding-top: 100px;
    padding-bottom: 250px;
}
.bar {
    height: 57px;
    width: 100%;
    background: rgb(58, 82, 85);
    position: absolute;
    top: 0;
}
.illustrationBird {
    position: absolute;
    height: 40px;
    top: 9px;
    left: 15px;
}
.nameTitle {
    color: #BEC4CD;
    position: absolute;
    top: 21px;
    left: 60px;
    bottom: 0;
    margin: auto;
    font-size: 1.1rem;
    font-family: 'Quicksand', sans-serif;
}
.nameTitle:hover {
    color: #dce3ee;
    cursor: default;
}
h2 {
    text-align: center;
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: 'Bree Serif', serif;
}
.posts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 1100px;
    margin: 0px auto;
}
.powerpoint {
    width: 95%;
    margin: 25px auto;
    margin-bottom: 0;
}
.singlePost {
    margin: 0 auto;
    margin-top: 75px;
}
h3 {
    color: rgb(65, 65, 65);
    font-size: 1.7rem;
    font-family: 'Quicksand', sans-serif;
    cursor: pointer;
}
.shareLink {
    width: 500px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}
.shareLink:hover {
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
}
.image img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 2px solid #f1f1f1;
}
.linkFooter {
    border-top: 1px solid rgb(220, 220, 220);
    background: #f1f1f1;
}
.origin {
    margin: 0;
    font-size: .9rem;
    color: #a5a5a5;
    padding-left: 15px;
    padding-top: 3px;
}
.title {
    margin: 0;
    padding: 0 15px 15px 15px;
    font-weight: bold;
    font-size: 1.2rem;
}
.imagePP {
    border-radius: 10px;
    width: 490px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
}
.imagePP:hover {
    -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
}
@media (max-width: 1100px) {
    .posts {
        width: 100%;
    }
    .singlePost {
        width: 600px;
        margin-top: 35px;
    }
    .powerpoint {
        width: 600px;
    }
}
@media (max-width: 992px) {
    .componentWrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .singlePost {
        width: 500px;
    }
    .powerpoint {
        width: 500px;
    }
}
@media (max-width: 660px) {
    h2 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 2.7rem;
        margin-bottom: 0px;
    }
}
@media (max-width: 500px) {
    .singlePost {
        width: 100%;
        margin-left: 15px;
    }
    .shareLink {
        width: 95%;
    }
    .powerpoint {
        padding-left: 15px;
        width: 100%;
    }
    .imagePP {
        width: 95%;
    }
}
@media (max-width: 450px) {
    .componentWrapper {
        padding-top: 55px;
        padding-bottom: 50px;
    }
    h2 {
        margin-bottom: 10px;
    }
}
</style>
