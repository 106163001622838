import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import about from '../views/about.vue'
import blog from '../views/blog.vue'
import voisteII from '../views/voisteII.vue'

import join from '../views/join.vue'
import transferDone from '../views/transferDone.vue'
import transferFailed from '../views/transferFailed.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/liikmeks',
    name: 'join',
    component: join
  },
  {
    path: '/uudised',
    name: 'blog',
    component: blog
  },
  {
    path: '/meist',
    name: 'about',
    component: about
  },
  {
    path: '/voiste-karjaar',
    name: 'voisteII',
    component: voisteII
  },
  {
    path: '/korras',
    name: 'transferDone',
    component: transferDone
  },
  {
    path: '/nihu',
    name: 'transferFailed',
    component: transferFailed
  },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

export default router
