<template>
    <body>
        <div class="app">
            <mainMenu @scroll='scroll' />
            <fixed-header :threshold="fixedMenuThreshold">
                <div class="main-fixed-menu">
                    <fixedMenu @scroll='scroll' />
                </div>
            </fixed-header>
            <fixedMobileMenu @scroll='scroll' />
            <router-view :scroll='scrollObject' :isActive='isActive' />
            <mainFooter />
        </div>
    </body>
</template>

<script>
import mainMenu from '@/components/menus/mainMenu.vue'
import fixedMenu from '@/components/menus/fixedMenu.vue'
import fixedMobileMenu from '@/components/menus/fixedMobileMenu.vue'
import mainFooter from '@/components/menus/footer.vue'

import fixedHeader from 'vue-fixed-header'

export default {
    name: 'App',
    watch: {
        '$route'(to) {
            if(to.name == 'home') {
                this.fixedMenuThreshold = 330
            } else {
                this.fixedMenuThreshold = 50
            }
        }
    },
    components: {
        mainMenu,
        fixedMenu,
        fixedMobileMenu,
        mainFooter,
        fixedHeader
    },
    data() {
        return {
            scrollObject: '',
            isActive: false,
            fixedMenuThreshold: 330
        }
    },
    methods: {
        scroll(object) {
            if(this.scrollObject == object) {
                this.isActive = true
                this.scrollObject = ''
            } else {
                this.scrollObject = object
                this.isActive = false
            }
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}
.main-fixed-menu {
    position: fixed;
    z-index: 1000;
    top: -200px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.main-fixed-menu.vue-fixed-header--isFixed {
    position: fixed;
    z-index: 1000;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.vgs__container { height: 80% !important; }
.app {
    padding-top: 0;
    background: rgb(250, 250, 250);
    overflow: hidden;
}
a:link { text-decoration: none; }

a:visited { text-decoration: none; }

a:hover { text-decoration: none; }

a:active { text-decoration: none; }

@media (max-width: 430px) {
    .vgs__container { height: 50% !important; }
}
</style>
