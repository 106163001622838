<template>
    <div class="componentWrapper">
        <div class="bar" @click="$router.push('/')">
            <img 
                class="illustrationBird"
                :src="require('@/assets/illustrations/bird.svg')" 
                alt=""
            >
            <h3 class="nameTitle">Tahkuranna Maastikukaitse</h3>
        </div>    
        <div class="contentWrapper">
            <newsSector />
        </div>  
    </div>
</template>

<script>
import newsSector from '@/components/voisteII/newsSector.vue'

export default {
    name: 'contactPage',
    components: {
        newsSector
    },
    
    data() {
        return {
            
        }
    },

    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Võiste II Karjäär',
        })
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.componentWrapper {
    position: relative;
    padding-top: 100px;
    padding-bottom: 75px;
}
.bar {
    height: 57px;
    width: 100%;
    background: rgb(58, 82, 85);
    position: absolute;
    top: 0;
}
.illustrationBird {
    position: absolute;
    height: 40px;
    top: 9px;
    left: 15px;
}
.nameTitle {
    color: #BEC4CD;
    position: absolute;
    top: 21px;
    left: 60px;
    bottom: 0;
    margin: auto;
    font-size: 1.1rem;
    font-family: 'Quicksand', sans-serif;
}
.nameTitle:hover {
    color: #dce3ee;
    cursor: default;
}
h2 {
    text-align: center;
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: 'Bree Serif', serif;
}

@media (max-width: 1010px) {
    .contact {
        width: 100%;
    }
}
@media (max-width: 992px) {
    .componentWrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
@media (max-width: 660px) {
   .single:first-child {
        width: 90%;
    }
    .single:last-child {
        width: 90%;
    }
    h2 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 2.7rem;
        margin-bottom: 0px;
    }
    .single {
        margin-bottom: 0px;
    }
}
@media (max-width: 450px) {
    .componentWrapper {
        padding-top: 55px;
        padding-bottom: 50px;
    }
    h2 {
        margin-bottom: 10px;
    }
}
</style>
