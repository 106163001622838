<template>
    <div class="wrapper">
        <img 
            class="illustrationBird"
            :src="require('@/assets/illustrations/birdThree.svg')" 
            alt=""
        >
        <h2>Soovid astuda liikmeks?</h2>
        <button @click="$router.push('/liikmeks')">Astu liikmeks</button>
    </div>
</template>

<script>
export default {
    name: 'newsSector',
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
.wrapper {
    overflow: hidden;
    position: relative;
    z-index: 1;
    text-align: center;
    width: 95%;
    margin: 0px auto;
    margin-bottom: 10px;
    border-radius: 100px;
    background: rgb(58, 82, 85);
    padding-top: 30px;
    padding-bottom: 40px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
}
.illustrationBird {
    position: absolute;
    width: 80px;
    right: 0;
    top: 20px;
    left: 600px;
    margin: auto;
}
h2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 2.5rem;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'Bree Serif', serif;
}
button {
    position: relative;
    top: 0;
    z-index: 100;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #5CA47B;
    border-radius: 20px;
    margin-right: 15px;
    margin-top: 10px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
button:hover {
    position: relative;
    top: -5px;
    background: #7EB581;
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

@media(max-width: 1000px) {
    .componentWrapper {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .illustrationBird {
        width: 65px;
        top: 38px;
        bottom: 0;
        right: 0;
        left: 300px;
        margin: auto;
    }
}
@media (max-width: 550px) {
    h2 {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.7rem;
    }
    .componentWrapper {
        padding-top: 7px;
        border-radius: 0px;
        width: 100%;
    }
    button {
        padding-left: 30px;
        padding-right: 30px;
    }
    .illustrationBird {
        width: 55px;
        left: 240px;
        top: 25px;
    }
}
</style>
