<template>
    <div class="componentWrapper">
        <div class="masthead">
            <div class="container">
                <img 
                    class="illustrationTree"
                    :src="require('@/assets/illustrations/tree.svg')" 
                    alt=""
                >
                <div class="wrapper">
                    <div class="photoContainerMobile">
                        <div>
                            <img 
                                class="illustrationBird"
                                :src="require('@/assets/illustrations/bird.svg')" 
                                alt=""
                            >
                            <div class="shape">
                                <img 
                                    class="bannerImg"
                                    :src="require('@/assets/images/bannerImage.jpg')" 
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                    <div class="title">
                        <h1>MTÜ Tahkuranna <br> Maastikukaitse</h1>
                        <div class="btns">
                            <p class="butSome" @click="supportUs()">Toeta meid</p>
                            <p class="butSome" @click="$router.push('/liikmeks')">Astu liikmeks</p>
                        </div>
                    </div>
                    <div class="photoContainer">
                        <div class="masthead-cards">
                            <img 
                                class="illustrationBird"
                                :src="require('@/assets/illustrations/bird.svg')" 
                                alt=""
                            >
                            <div class="shape">
                                <img 
                                    class="bannerImg"
                                    :src="require('@/assets/images/bannerImage.jpg')" 
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <svg style="pointer-events: none" class="wave" width="100%" height="50px" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 75">
                <defs>
                    <clipPath id="a">
                        <rect class="a" width="1920" height="75"></rect>
                    </clipPath>
                </defs>
                <title>wave</title>
                <g class="b">
                    <path class="c" d="M1963,327H-105V65A2647.49,2647.49,0,0,1,431,19c217.7,3.5,239.6,30.8,470,36,297.3,6.7,367.5-36.2,642-28a2511.41,2511.41,0,0,1,420,48"></path>
                </g>
                <g class="b">
                    <path class="d" d="M-127,404H1963V44c-140.1-28-343.3-46.7-566,22-75.5,23.3-118.5,45.9-162,64-48.6,20.2-404.7,128-784,0C355.2,97.7,341.6,78.3,235,50,86.6,10.6-41.8,6.9-127,10"></path>
                </g>
                <g class="b">
                    <path class="d" d="M1979,462-155,446V106C251.8,20.2,576.6,15.9,805,30c167.4,10.3,322.3,32.9,680,56,207,13.4,378,20.3,494,24"></path>
                </g>
                <g class="b">
                    <path class="d" d="M1998,484H-243V100c445.8,26.8,794.2-4.1,1035-39,141-20.4,231.1-40.1,378-45,349.6-11.6,636.7,73.8,828,150"></path>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
// window.dataLayer = window.dataLayer || [];
//   function gtag(){window.dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'GA_TRACKING_ID');

export default {
    name: 'mainBanner',
    components: {
        
    },
    methods: {
        supportUs() {
            this.$emit('scroll')
            this.$gtag('event', 'Nupp - Toeta meid', {
                'event_category': 'Navigeerimine',
                'event_label': 'Toeta meid',
                'value': 3
            });
        }
    }
}
</script>

<style scoped>
body {
    margin-top:0px
}
.masthead {
    overflow: hidden;
    padding: 12.5rem 0 13.5rem 0;
    position: relative;
    background-color: rgb(58, 82, 85);
    background-size: cover;
    z-index: 0
}

.illustrationTree {
    position: absolute;
    bottom: 25px;
    left: 10px;
    height: 200px;
}
.illustrationBird {
    position: absolute;
    height: 90px;
    top: 95px;
    right: 270px;
}
.bannerImg {
    position: absolute;
    top: -90px;
    right: -0%;
    width: 100%;
    opacity: .9;
    border-radius: 00px;
    -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
}
.title {
    margin-top: 00px;
}
.photoContainerMobile {
    display: none;
}
.photoContainer {
    margin-top: 10px;
}
.masthead svg.wave {
    position: absolute;
    bottom: -1px;
    left: 0
}

.masthead h1 {
    color: #fff;
    line-height: 1.2;
    z-index: 1;
    margin-top: -25px;
    font-size: 4.5rem;
    font-family: 'Patua One', cursive;
}
.btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
}
.butSome {
    position: relative;
    top: 0;
    z-index: 100;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #5CA47B;
    border-radius: 20px;
    margin-right: 15px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.butSome:hover {
    position: relative;
    top: -5px;
    background: #7EB581;
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.masthead a {
    color: rgba(255, 255, 255, .8);
    text-decoration: underline;
    z-index: 1
}

.masthead a:hover {
    color: #fff
}

.masthead a:active {
    text-decoration: none
}
.masthead .masthead-cards .shape {
    overflow: hidden;
    position: absolute;
    width: 600px;
    height: 300px;
    right: 270px;
    top: -30px;
    bottom: 0;
    margin: auto;
    background-color: rgba(253, 252, 243, 0.6);
    z-index: -1;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    border: 4px solid white;
    -o-object-fit: contain;
       object-fit: contain;
}


.masthead .masthead-cards .card {
    display: none;
    opacity: 0;
    font-size: .9rem;
    width: 220px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: .05rem;
    color: #ffffff;
    background: rgb(70, 131, 180);
    text-decoration: none;
    -webkit-transition: .15s all;
    -o-transition: .15s all;
    transition: .15s all;
    border-radius: 100px;
}

.masthead .masthead-cards .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem
}

.masthead .masthead-cards .card:active {
    margin-top: inherit;
    margin-bottom: inherit
}

.masthead .masthead-cards .card.border-bottom-green:hover {
    color: #ffc107
}
.masthead .masthead-cards .card.border-bottom-yellow:hover {
    color: #ffc107
}

.masthead-page {
    padding: 1rem 0 4rem
}
.d-flex a {
    position: absolute;
    top: 30px;
    bottom: 0;
    margin: auto;
    /* margin-top: -15px;
    margin-left: 15px;
    margin-right: 15px; */
}
.d-flex a:first-child {
    left: 10px;
}
.d-flex a:last-child {
    right: 10px;
}

.a {
    fill: none;
}

.b {
    -webkit-clip-path: url(#a);
            clip-path: url(#a);
}

.c,
.d {
    fill: #F4EDE4;
}

.d {
    opacity: 0.5;
    isolation: isolate;
}

.border-bottom-yellow {
    border-color: #ffc107!important;
}

.border-bottom-red {
    border-color: #dd3d31!important;
}

.border-bottom-blue {
    border-color: #2092ed!important;
}

.border-bottom-green {
    border-color: #28a745!important;
}

.border-bottom-blue, .border-bottom-green, .border-bottom-red, .border-bottom-yellow {
    border-bottom: .4rem solid!important;
}
.shadow-lg {
    -webkit-box-shadow: 0 2rem 1.5rem -1.5rem rgba(33,37,41,.15),0 0 1.5rem .5rem rgba(33,37,41,.05)!important;
            box-shadow: 0 2rem 1.5rem -1.5rem rgba(33,37,41,.15),0 0 1.5rem .5rem rgba(33,37,41,.05)!important;
}
.border-0 {
    border: 0!important;
}
@media (max-width: 1700px) {
    .title {
        left: 0;
    }
    .masthead .masthead-cards .shape {
        right: 25px;
    }
    .illustrationBird {
        right: 25px;
        top: 100px;
    }
}
@media (max-width: 1290px) {
    .photoContainer {
        display: none;
    }
    .title {
        width: 100%;
        text-align: center;
    }
    .btns {
        text-align: center;
        width: 450px;
        margin: 0 auto;
    }
    .butSome {
        margin: 0 auto;
    }
    .masthead {
        padding: 27.5rem 0 14.5rem 0;
    }

    .photoContainerMobile {
        display: initial;
    }
    .shape {
        overflow: hidden;
        position: absolute;
        width: 550px;
        height: 275px;
        left: 0;
        right: 0;
        top: -350px;
        bottom: 0;
        margin: auto;
        background-color: rgba(253, 252, 243, 0.6);
        z-index: -1;
        border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
        border: 4px solid white;
        -o-object-fit: contain;
           object-fit: contain;
    }
    .illustrationBird {
        right: 0;
        left: 480px;
        top: -120px;
        bottom: 0;
        margin: auto;
    }
}
@media (max-width: 992px) {
    .masthead {
        padding: 17.5rem 0 14.5rem 0;
    }
    .shape {
        top: -460px;
    }
    .title {
        margin-top: 90px;
    }
    .title h1 {
        margin-bottom: 30px;
    }
    .illustrationBird {
        left: 480px;
        top: -200px;
    }
}
@media (max-width: 768px) {
    .masthead h1 {
        font-size: 3rem;
    }
    .bannerImg {
        top: -20px;
    }
    .shape {
        width: 350px;
        height: 200px;
        left: 0;
        right: 0;
        top: -380px;
        bottom: 0;
    }
    .illustrationBird {
        left: 300px;
        top: -200px;
    }
    .masthead {
        padding: 13.5rem 0 14.5rem 0;
    }
}
@media (max-width: 500px) {
    .shape {
        width: 340px;
        height: 200px;
        left: 0;
        right: 0;
        top: -240px;
        bottom: 0;
    }
    .illustrationBird {
        width: 80px;
        left: 260px;
        top: -50px;
    }
    .masthead h1 {
        margin-top: -40px;
        font-size: 2.5rem;
    }
    .masthead {
        padding: 12.5rem 0 9.4rem 0;
    }
    .btns {
        position: absolute;
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 30px;
        margin-left: -12px;
    }
    .butSome {
        width: 45%;
        margin: 0 auto;
        font-size: .9rem;
        padding-left: 00px;
        padding-right: 00px;
    }
    .illustrationTree {
        display: none;
    }
    .title h1 {
        margin-bottom: 0px;
    }
}
</style>
