<template>
<div class="headerWrapper">
    <header class="main-header">
        <div class="menuContainer">
            <nav class="navContainer">
                <img 
                    class="logoImg"
                    @click="changePage('home')"
                    :src="require('@/assets/logo/mainLogo.svg')" 
                    alt=""
                >
                <div id="mainMenu">
                    <ul class="navMenu">
                        <li>
                            <a @click="changePage('home')">Pealeht</a>
                        </li>
                        <li>
                            <a @click="changePage('about')">Meist</a>
                        </li>
                        <li>
                            <a @click="changePage('news')">Uudised</a>
                        </li>
                        <li>
                            <a @click="changePage('voisteII')">Võiste II karjäär</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="buttons">
            <button @click="burgerMenuScroll('supportUs')">Toeta meid</button>
        </div>
    </header>
    <burgerMenu class="burgerMenu" @burgerMenuScroll='burgerMenuScroll' />
</div>
</template>

<script>
import burgerMenu from '@/components/menus/burgerMenu.vue'

export default {
    name: 'mainMenu',
    components: {
        burgerMenu,
    },
    methods: {
        changePage(page) {
            this.$gtag('event', 'Navigeerimine', {
                'Menüü': 'Fikseeritud peamenüü',
                'value': 4
            });
            if(page == 'home') {
                if(this.$route.name != 'home') {
                    scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 100)
                } else {
                    scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }
            }
            if(page == 'contact') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/kontakteeru')
                }, 100)
            }
            if(page == 'news') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/uudised')
                }, 100)
            }
            if(page == 'voisteII') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/voiste-karjaar')
                }, 100)
            }
            if(page == 'about') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/meist')
                }, 200)
            }
        },

        scroll(object) {
            if(object == 'project') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'project')
                    }, 100);
                } else {
                    this.$emit('scroll', 'project')
                }
            }
            if(object == 'about') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'about')
                    }, 100);
                } else {
                    this.$emit('scroll', 'about')
                }
            }
        },

        burgerMenuScroll(object) {
            this.$gtag('event', 'Navigeerimine', {
                'Menüü': 'Fikseeritud peamenüü',
                'value': 4
            });
            this.$gtag('event', 'Nupp - Toeta meid', {
                'event_category': 'Navigeerimine',
                'event_label': 'Toeta meid',
                'value': 3
            });
            if(object == 'project') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'project')
                    }, 100);
                } else {
                    this.$emit('scroll', 'project')
                }
            }
            if(object == 'about') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'about')
                    }, 100);
                } else {
                    this.$emit('scroll', 'about')
                }
            }
            if(object == 'supportUs') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'supportUs')
                    }, 100);
                } else {
                    this.$emit('scroll', 'supportUs')
                }
            }
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

[data-target="#mainMenu"] {
  position: relative;
  z-index: 999;
}
.logoImg {
    width: 100px;
    margin-top: -5px;
    margin-right: 30px;
    margin-left: 30px;
    cursor: pointer;
}
.name {
    color: #BEC4CD;
    position: absolute;
    top: 25px;
    right: 30px;
    bottom: 0;
    margin: auto;
    font-size: 1.2rem;
    font-family: 'Quicksand', sans-serif;
}
.name:hover {
    color: #dce3ee;
    cursor: default;
}
.navMenu {
    list-style-type: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px;
}
.navContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
#mainMenu li {
    margin-top: 0px;
}
#mainMenu li > a {
  font-size: 17px;
  letter-spacing: 1px;
  color: rgb(50, 50, 50);
  font-weight: bold;
  position: relative;
  z-index: 1;
  text-decoration: none;
  font-family: 'DM sans', sans-serif;
}

.main-header.fixed-nav #mainMenu li > a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin-right: 30px;
}

#mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1px;
  background: rgb(0, 0, 0);
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#mainMenu li > a:hover::before,
#mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li > a::before {
  background: #000;
}

.main-header.vue-fixed-header {
    position: fixed;
    top: -400px;
    left: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.main-header {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    width: 1300px;
    height: 76px;
    background: rgb(255, 255, 255);
    padding-top: 10px;
    padding-bottom: 20px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    border-radius: 40px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
}
.main-header.vue-fixed-header--isFixed {
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-box-shadow: 0px 2px 3px -2px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5);
}
.main-header.fixed-nav {
  top: 0;
  background: rgb(0, 0, 0);
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.main-header .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
.main-header .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
a:hover {
    cursor: pointer;
}
.burgerMenu {
    display: none;
}
.buttons button {
    position: absolute;
    right: 0;
    top: 8px;
    right: 20px;
    z-index: 100;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 7px;
    padding-bottom: 7px;
    background: rgb(121,2,82);
    background: linear-gradient(45deg, rgba(121,2,82,1) 0%, rgba(252,70,107,1) 100%); 
    border-radius: 7px;
    border: none;
    margin-right: 15px;
    margin-top: 10px;
    -webkit-transition: background .4s ease-out;  
    -moz-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
}
button:hover {
    /* background: rgb(252,70,107);
    background: linear-gradient(45deg, rgba(252,70,107,1) 0%, rgba(121,2,82,1) 100%);  */
    -webkit-transition: background .4s ease-out;  
    -moz-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
    background-position: 179px; 
}
@media (max-width: 1320px) {
    .main-header {
        width: 1000px;
    }
    .main-header.vue-fixed-header--isFixed {
        left: 0;
        right: 0;
        margin: auto;
    }
}
@media (max-width: 1030px) {
    .main-header {
        width: 850px;
    }
}
@media (max-width: 992px) {
    .main-header {
        display: none;
    }
    .fixedBurgerMenu {
        top: 0px;
    }
    .illustrationBird {
        height: 40px;
        top: 7px;
        left: 15px;
    }
    .name {
        top: 16px;
        left: 60px;
        bottom: 0;
        font-size: 1.2rem;
    }
    #mainMenu {
        display: none;
    }
}
@media (max-width: 400px) {
    .illustrationBird {
        height: 40px;
        top: 7px;
        left: 7px;
    }
    .name {
        top: 16px;
        left: 52px;
        font-size: 1.1rem;
    }
}
</style>
