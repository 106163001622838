<template>
    <div class="componentWrapper" id="about">
        <img class="illustrationDivider" :src="require('@/assets/illustrations/dividerTwo.svg')"/>
        <br>
        <div class="imageGallery">
            <img class="image" v-for="(image, i) in images" :src="image" :key="i" @click="index = i">
        </div>
        <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
        <br>
        <img 
            class="illustrationBird"
            :src="require('@/assets/illustrations/birdTwo.svg')" 
            alt=""
        >
        <h3>Keskkonnamõjudest</h3>
        <p>
            Keskkonnaamet võttis kaeveloa menetlemise käigus seisukoha, et karjääri rajamisega võivad kaasneda negatiivsed mõjud ümbritsevale loodusele. Seetõttu andis ta <b>kaeveloa taotlejale</b> ülesande leida ekspert, kes koostaks <b>eksperthinnangu</b>(nimetatud ka kui eelhinnang) võimalike mõjude kohta.
            <br>
            <br>
            Karjääri rajaja tellis nõutud hinnangu ettevõttelt <b>Alkranel OÜ</b>, millise juures on projektijuhiks märgitud <b>Elar Põldvere</b>, kaasas veel <b>Ants Tull</b>.
            <br>
            <br>
            Eksperdid võtsid oma töö kokku sõnadega: <i>“Käesolev töö, millest järeldub, et <b>objektiivsetel alustel mõju</b> eeldusi analüüsitud elupaigatüübile ja sellega seonduvale (mh tunnusliigid) <b>ei ole</b> [..]”</i>
            <br>
            Antud eksperthinnangu asjakohasust saavad kõik ise hinnata. See on loetav <b class="link" @click="openPdf('analysis')">siit</b>.
            <br>
            <br>
            Ühtlasi esitati see Keskkonnaametile, mida seal peeti piisavalt asjakohaseks ning küllalt olulist teavet sisaldavaks, et Keskkonnaamet otsustas mitte algatada nendepoolset keskkonnamõjude hindamist. See tähendab, nõustusid hinnangu järeldusega.
            <br>
            <br>
            Oleme antud eksperthinnangu osas võtnud seisukoha, et <b>hinnangus puudub analüüs mõjudest ümbritsevale keskkonnale piisaval keerukuse astmel.</b>
            <br>
            Seetõttu on õiglane kui Keskkonnaamet algatab omapoolse keskkonnamõjude hindamise, kaasates pädevad eksperdid, kes suudavad hinnata ka <b>kahe karjääri koosmõju</b> ümbritsevatele kaitseväärtustele (Uulu-Võiste ja Luitemaa kaitsealad).
        </p>
        <div class="imageGalleryTwo">
            <img class="imageTwo" v-for="(image, i) in imagesTwo" :src="image" :key="i" @click="indexTwo = i">
        </div>
        <vue-gallery-slideshow :images="imagesTwo" :index="indexTwo" @close="indexTwo = null"></vue-gallery-slideshow>
        <h3>Maastiku muutmine</h3>
        <p>
            Veekogu rajamine on kujundlik ja põhimõtteline ja lõplik otsus looduse kujundamise osas.
            <b>Maastik on taustsüsteem millegi toimumiseks. Seega, kas soovime ala muuta igaveseks?</b>
            <br>
            <br>
            Rahva kaasatus peab olema siinkohal õigustatud ja arvestatud. Mis on tähtsaim pikemas
            perspektiivis kui me teame, et <b>sellise miljööga metsi Pärnu ümbruses ei ole palju?</b>
            <br>
            Tähtis on üldisem arusaam piirkonna arengust ja inimtervise seisukohast.
            <br>
            <br>
            Kõnesoleval alal oli sajandi alul mets mis raiuti. Metsa puhul on tegu on taastuva ressursiga
            ja ka visuaalselt samaväärse olemusega paiga taastumisega. Suures osas on kõnealusel
            kinnistul maad võtnud taas noored männid ning ühel päeval saab sinna uuesti täisväärt
            männik.
            <br>
            <br>
            Karjääri rajamisel muutub maastikukasutus otstarbeliselt ja selle muutuse suhtes eelnevat
            mõjuhinnangut ei ole teostatud. <b>Tegemist on alaga, mis külgneb väga aktiivselt kasutusel
            oleva alaga ja mõjutab lisaks loomadele ka inimtegevusega seonduvat.</b>
            <br>
            <br>
            Kavandatava karjäärialaga piirneva kaitseala kasutustihedus inimeste poolt on viimastel
            aastatel suuresti tõusnud ja ala kasutavad aktiivselt, lisaks kohalikele, ka Pärnu elanikud.
            Seetõttu tuleb kogu keskkonda hinnata kompleksselt ja kaasata maastikukujunduse,
            otstarbekuse ja tervislikkuse aspekt ja spetsialiteet.
            <br>
            <b>Müra levik ja visuaalne häiring takistab otseselt karjääriga külgneva metsa kasutust</b>,
            selliselt nagu see on Jõulumäe Tervisespordikeskusega seoses välja kujunenud ja sellega
            arvestamine peaks olema tähtis faktor ning <b>prioriteet inimtervise seisukohalt.</b>
            <br>
            Kõnesoleval maastikul on miljööväärtus ja see ei ole ainult majanduslik ressurss, seega ei
            saa kõnesolevat piirkonda hinnata ainult looduskaitsja või kasusaaja seisukohast.
            <br>
            <br>
            Mõju inimesele on vaja hinnata selle ala uuendatavast kujundusest tingituna ja
            kujundamine peab olema põhjendatud.
            <br>
            <br>
            Kõike eelpooltoodut arvestades, tekib küsimus - miks nii olulise, avalikku huvi omava ja
            oluliselt looduskeskkonda kahjustava objekti puhul <b>jäeti ära keskkonnamõjude hindamine?</b>
            <br>
            <br>
            <b>Esitatud argumentidele tuginedes seisame selle eest, et:</b>
            <ul>
                <li>keskkonnamõjude hindamise algatamata jätmise eelnõu avaldataks avalikes allikates</li>
                <li>Võiste II karjääri keskkonnaloa menetlus ja karjääri avamine peatatakse seniste
                ebapiisavate põhjenduste valguses</li>
                <li>avada avalikku huvi puudutav, avalik teemapõhine arutelu edasiste otsuste tegemiseks</li>
            </ul>
        </p>
        <br>
        <h3>Kivisisalike kaitsest</h3>
        <p>
            Planeeritava karjääri ala on <a @click="openPdf('kivisisalik')" style="color: steelblue; cursor: pointer; text-decoration: underline"><b>II kaitsekategooria</b></a> alla kuuluva kivisisaliku elupaik. II kaitsekategooria alla kuulub peale kivisisaliku veel 51 selgroogset liiki.
            <br>
            <br>
            Keskkonnaamet on seisukohal, et looduslikes kooslustes ei ole sageli tagatud kivisisalikule oluliste liivaste alade säilimine, mistõttu on karjäärid kujunenud sellele liigile olulisteks elupaikadeks.
            <br>
            Proovimaks samale alale mahutada nii karjääri rajamise kui kivisisaliku elupaiga kaitse, andis amet kaeveloa taotlejale nõu, et kivisisalikule tuleb luua <b>korrastamise käigus</b> karjääri <b>idapiirile</b> lahtise liivaga ala.
            <br>
            <br>
            Kui taotleja selle tingimuse täidab, leidis Keskkonnaamet, et <b>keskkonnamõju hindamist teostada pole vaja.</b>
            <br>
            <br>
            Seesuguse nõupidamise tulemusel esitati Keskkonnaametile esialgses taotluses plaan, kus on välja toodud planeeritav ala, mis saaks olema kivisisalikele.
            <br>
            <br>
            Sellest hoolimata, et kivisisalike eksistents antud kinnistul kohe avalikustati, ei takistanud see Keskkonnaametil edaspidi tugineda looduskaitseseaduse § 53 lõikele 1, mille alusel on I ja II kaitsekategooria liigi isendi täpse elupaiga asukoha avalikustamine massiteabevahendites keelatud.
            <br>
            Keskkonnaamet, rõhutades seda seadust, <b>ei avalikustanud keskkonnamõjude hindamise algatamata jätmise eelnõu</b> ning avalikkusel jäi ligipääs sellele saamata. See tähendab, avalikkusel ei ole võimalik teada <b>millistel kaalutlustel</b> antud otsus tehti.
            <br>
            <br>
            Häädemeeste Vallavalitsus reageeris esimesena, pärides eelnõu avaldamist, sest “Uulu-Võiste maastikukaitseala kaitsekorralduskava aastateks 2017-2026” avaldamine aastal <b>2016</b> oli teinud kivisisalike elupaiga antud kinnistul avalikuks teadmiseks.
            <br>
            Tsiteerides seda kava: “[..] kaitseala <b>lõunatipus</b> olev elupaik on seotud Tahkuranna karjääriga ja <b>jääb osaliselt kaitsealast välja.”</b>
            <br>
            <br>
            Vallavalitsus sai eitava vastuse ning eelnõu on senini avalikkusele kättesaamatu.
            <br>
            <br>
            Kivisisalike kohta on eksperthinnangus vaid nii palju, et kuna plaanis on ette nähtud liivane nõlv, mis võib olla sobilik kivisisalike elupaigaks, on sellega korras ning lähemat mõjude analüüsi suhtes kaitsealuse liigiga teostada pole vaja.
            <br>
            Väideti muujuures, et kivisisalike elutingimusi antud karjääri rajamine hoopis parendab.

        </p>
        <div class="imageGalleryTwo">
            <img class="imageTwo" v-for="(image, i) in imagesThree" :src="image" :key="i" @click="indexThree = i">
        </div>
        <vue-gallery-slideshow :images="imagesThree" :index="indexThree" @close="indexThree = null"></vue-gallery-slideshow>
        <br>
        <h3>Poliitikast</h3>
        <p>
            Häädemeeste vallavalitsus eesotsas vallavanem <b>Külliki Kiiver'iga</b> on seisukohal, et kaevandamisloa andmine ei ole põhjendatud. <b>Häädemeeste vallas on piisavalt mäeeraldisi</b> riiklikult oluliste taristuobjektide rajamiseks ja rekonstrueerimiseks ja seda eriti just liivavaru osas.
            <br>
            <br>
            Kui vald on sellise seisukoha võtnud, olles kaeveloa taotluse menetluse protsessi <b>kooskõlastaja</b> ehk üks osapool, kelle nõusolekuta kogu protsess lakkab, siis miks tänasel päeval veel antud luba menetletakse?
            <br>
            Tõde on see, et eelmine vallavalitsus eesotsas vallavanem <b>Karel Tölp'iga</b> suutis kas pahatahtlikkuse, või häbiväärse ebakompetentsuse tõttu menetluse kooskõlastada.
            <br>
            <br>
            Kooskõlastamise protsess ise oli lühidalt selline, et Keskonnaamet saatis vallale kirja, et teil on aega <b>60</b> päeva, avaldamaks oma arvamus. Kirja lõpus märgiti, et <b>seadusest tulenevalt tähtaega pikendada ei saa.</b> Vaikimist loetakse nõusolekuks.
            <br>
            <br>
            Hoolimata sellest küsib vald tähtaja pikendust, ajal, mil tähtajani oli veel <b>26</b> päeva. “Tulenevalt asjaolust, et juulikuusse ei ole planeeritud Häädemeeste Vallavalitsuse ega Häädemeeste Vallavolikogu istungeid.”
            See tähendab, juulis puhatakse.
            <br>
            <br>
            <b>6</b> päeva enne tähtaja lõppu kordab Keskonnaamet oma vastuses, et Haldusmenetluse seadusest tulenevalt ei ole võimalik tähtaegu pikendada. Hiljem esitatud arvamus ei ole loa menetlemisel Keskonnaameti jaoks siduv.
            <br>
            Vallavalitsus saatis oma eitava arvamuse <b>17</b> päeva peale tähtaega, olgugi et Keskkonnaamet selgesõnaliselt kahel korral teavitas, et arvamus on siduv vaid juhul, kui see on esitatud etteantud tähtajaks.
            <br>
            <br>
            Oluline on märkida, et kohalik elanikkond esitas oma vastu-allkirjad valda <b>47</b> päeva enne tähtaega. Milliseid praktiliselt ignoreeriti.
        </p>
    </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
    name: 'newsSector',
    components: {
        VueGallerySlideshow,
    },
    props: {
        scrollTo: {},
        activeScrollTo: {}
    },
    watch: {
        scrollTo: {
            handler (val) {
                if(val == 'project') {
                    document.getElementById("project").scrollIntoView({behavior: "smooth"});
                }
                if(val == 'about') {
                    document.getElementById("about").scrollIntoView({behavior: "smooth"});
                }
            }
        },
        activeScrollTo: {
            handler (val) {
                if(val == 'project') {
                    document.getElementById("project").scrollIntoView({behavior: "smooth"});
                }
                if(val == 'about') {
                    document.getElementById("about").scrollIntoView({behavior: "smooth"});
                }
            }
        },
    },
    data() {
        return {
            images: [
                require('@/assets/images/drone/johan-raisi kinnistu.jpg'),
            ],
            imagesTwo: [
                require('@/assets/images/drone/screen_cc4de44c52234c7c_1659006108039.jpg'),
                require('@/assets/images/drone/screen_7bbd88a958228d49_1658759970346.jpg'),
            ],
            imagesThree: [
                require('@/assets/images/drone/Kivisisalik.jpg'),
                require('@/assets/images/drone/Zauneidechse_1469_(detail).jpg'),
            ],
            index: null,
            indexTwo: null,
            indexThree: null,
        }
    },
    methods: {
        openPdf(type) {
            if(type == 'analysis') {
                window.open("/files/Voiste_II_2180_elupaik_EH_25_02.22_alkranel.pdf", '_blank');
            }
            if(type == 'kivisisalik') {
                window.open("https://keskkonnaamet.ee/elusloodus-looduskaitse/looduskaitse/liigikaitse#ii-kaitsekategooria", '_blank');
            }
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.componentWrapper {
    position: relative;
    background: rgb(250,250,250);
    padding-top: 100px;
    padding-bottom: 100px;
}
.illustrationDivider {
    width: 500px;
    margin: 0px auto;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 30px;
}
.illustrationBird {
    position: absolute;
    height: 90px;
    left: 0;
    right: 750px;
    margin: auto;
    margin-top: 5px;
}
h2 {
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Bree Serif', serif;
}
h3 {
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Bree Serif', serif;
}
p {
    width: 800px;
    margin: 50px auto;
    margin-bottom: 20px;
    color: rgb(90, 90, 90);
    font-size: 1.1rem;
    font-family: 'Quicksand', sans-serif;

}
.link {
    color: steelblue; 
    cursor: pointer;
    text-decoration: underline;
}
.link:hover {
    opacity: .8;
    text-decoration: underline;
}
.carouselWrapper {
    overflow: hidden;
    border-radius: 10px;
    width: 1000px;
    margin: 80px auto;
    margin-bottom: 0px;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
}
.carousel {
    width: 100%;
}
.image {
    margin-left: 15px;
    margin-bottom: -20px;
    border-radius: 5px;
    width: 400px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
}
.image:hover {
    -webkit-filter: grayscale(30%);
            filter: grayscale(30%);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
}
.imageGallery {
    width: 825px;
    margin: 0 auto;
    text-align: center;
}
.imageTwo {
    margin-left: 15px;
    margin-right: 5px;
    margin-bottom: 0px;
    border-radius: 5px;
    width: 400px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
}
.imageTwo:hover {
    -webkit-filter: grayscale(30%);
            filter: grayscale(30%);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
}
.imageGalleryTwo {
    width: 845px;
    margin: 0 auto;
    text-align: center;
}
@media (max-width: 1000px) {
    .carouselWrapper {
        width: 90%;
    }
    .imageGallery {
        width: 630px;
        margin: 0 auto;
    }
    .imageGalleryTwo {
        width: 630px;
        margin: 0 auto;
    }
}
@media (max-width: 680px) {
    .imageGallery {
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }
    .imageGalleryTwo {
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }
    .image {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
    .imageTwo {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
}
@media (max-width: 1000px) {
    p {
        width: 90%;
    }
    h2, h3 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 2.5rem;
    }
    .illustrationBird {
        display: none;
    }
}
@media (max-width: 550px) {
    .illustrationDivider {
        width: 90%;
    }
    .componentWrapper {
        padding-top: 70px;
    }
    h2, h3 {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 2.1rem;
    }
    p {
        margin-top: 20px;
    }
    .carouselWrapper {
        width: 95%;
        margin-top: 30px;
    }
    .componentWrapper {
        padding-bottom: 50px;
    }
    
}
</style>
