<template>
<div class="menuWrapper">
    <img 
        class="logoImg"
        @click="changePage('home')"
        :src="require('@/assets/logo/mainLogo.svg')" 
        alt=""
    >
    <div class="mainMenu">
        <span>
            <a @click="changePage('home')">Pealeht</a>
        </span>
        <span>
            <a @click="changePage('about')">Meist</a>
        </span>
        <span>
            <a @click="changePage('news')">Uudised</a>
        </span>
        <span>
            <a @click="changePage('voisteII')">Võiste II karjäär</a>
        </span>
    </div>
    <div class="buttons">
        <button @click="burgerMenuScroll('supportUs')">Toeta meid</button>
    </div>
</div>
</template>

<script>

export default {
    name: 'fixedMobileMenu',
    methods: {
        changePage(page) {
            this.$gtag('event', 'Navigeerimine', {
                'Menüü': 'Fikseeritud peamenüü',
                'value': 4
            });
            if(page == 'home') {
                if(this.$route.name != 'home') {
                    scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 100)
                } else {
                    scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }
            }
            if(page == 'contact') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/kontakteeru')
                }, 100)
            }
            if(page == 'news') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/uudised')
                }, 100)
            }
            if(page == 'voisteII') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/voiste-karjaar')
                }, 100)
            }
            if(page == 'about') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                setTimeout(() => {
                    this.$router.push('/meist')
                }, 200)
            }
        },

        scroll(object) {
            if(object == 'project') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'project')
                    }, 100);
                } else {
                    this.$emit('scroll', 'project')
                }
            }
            if(object == 'about') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'about')
                    }, 100);
                } else {
                    this.$emit('scroll', 'about')
                }
            }
        },

        burgerMenuScroll(object) {
            this.$gtag('event', 'Navigeerimine', {
                'Menüü': 'Fikseeritud peamenüü',
                'value': 4
            });
            this.$gtag('event', 'Nupp - Toeta meid', {
                'event_category': 'Navigeerimine',
                'event_label': 'Toeta meid',
                'value': 3
            });
            if(object == 'supportUs') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'supportUs')
                    }, 100);
                } else {
                    this.$emit('scroll', 'supportUs')
                }
            }
        }
    }
}
</script>

<style scoped>
.menuWrapper {
    display: none;
    position: fixed;
    z-index: 1000;
    bottom: 0;
    height: 65px;
    background: white;
    border-top: 1px solid rgb(230,230,230);
    width: 100%;
}
.logoImg {
    position: absolute;
    bottom: 0;
    top: 2px;
    left: 15px;
    margin: auto;
    width: 110px;
    height: 35px;
    cursor: pointer;
}
.buttons button {
    position: absolute;
    height: 38px;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    z-index: 100;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 7px;
    padding-bottom: 7px;
    background: rgb(121,2,82);
    background: -o-linear-gradient(45deg, rgba(121,2,82,1) 0%, rgba(252,70,107,1) 100%);
    background: linear-gradient(45deg, rgba(121,2,82,1) 0%, rgba(252,70,107,1) 100%); 
    border-radius: 7px;
    border: none;
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
}
button:hover {
    /* background: rgb(252,70,107);
    background: linear-gradient(45deg, rgba(252,70,107,1) 0%, rgba(121,2,82,1) 100%);  */
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
    background-position: 179px; 
}
.mainMenu {
    position: absolute;
    left: 0;
    right: 95px;
    top: 10px;
    bottom: 0;
    height: 30px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-family: 'DM sans';
    font-size: 1.1rem;
}
span {
    cursor: pointer;
    color: rgb(50,50,50);
}
@media (max-width: 992px) {
    .menuWrapper {
        display: initial;
    }
}
@media (max-width: 750px) {
    .mainMenu {
        right: 65px;
        font-size: 1rem;
    }
}
@media (max-width: 650px) {
    .mainMenu {
        right: 65px;
        font-size: 1rem;
        width: 40%;
    }
    span:first-child {
        display: none;
    }
}
@media (max-width: 600px) {
    .mainMenu {
        display: none;
    }
}
</style>
