<template>
    <div class="componentWrapper">
        <div class="bar">
            <img 
                class="illustrationBird"
                :src="require('@/assets/illustrations/bird.svg')" 
                alt=""
            >
            <h3 class="nameTitle">Tahkuranna Maastikukaitse</h3>
        </div>
        <img 
            class="icon"
            :src="require('@/assets/icons/cross.svg')" 
            alt=""
        >
        <h2>Annetus sooritamine ebaõnnestus, <br> proovige uuesti!</h2>
        <p class="titleText"></p>
        <button @click="$router.push('/')">Tagasi pealehele</button>
    </div>
</template>

<script>


export default {
    name: 'transferDone',
    components: {
        
    },
    data() {
        return {
            
        }
    },

    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Makse ebaõnnestus',
        })
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.componentWrapper {
    position: relative;
    padding-top: 130px;
    padding-bottom: 200px;
    text-align: center;
}
.bar {
    height: 57px;
    width: 100%;
    background: rgb(58, 82, 85);
    position: absolute;
    top: 0;
}
.illustrationBird {
    position: absolute;
    height: 40px;
    top: 9px;
    left: 15px;
}
.nameTitle {
    color: #BEC4CD;
    position: absolute;
    top: 21px;
    left: 60px;
    bottom: 0;
    margin: auto;
    font-size: 1.1rem;
    font-family: 'Quicksand', sans-serif;
}
.nameTitle:hover {
    color: #dce3ee;
    cursor: default;
}
.icon {
    width: 200px;
    margin-bottom: 20px;
}
h2 {
    text-align: center;
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 50px;
    font-family: 'Bree Serif', serif;
}
p {
    color: rgb(90, 90, 90);
    font-size: 1.1rem;
}
.titleText {
    text-align: center;
    width: 700px;
    margin: 0 auto;
}

button {
    position: relative;
    top: 0;
    z-index: 100;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgb(26, 137, 189);
    border-radius: 20px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
button:hover {
    position: relative;
    top: -5px;
    background: rgb(41, 162, 218);
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
@media (max-width: 992px) {
    .componentWrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
@media (max-width: 700px) {
    
}
@media (max-width: 450px) {
    .componentWrapper {
        padding-top: 55px;
        padding-bottom: 50px;
    }
    h2 {
        margin-bottom: 10px;
    }
    
}
</style>
