<template>
    <div class="componentWrapper" v-on-clickaway="clickAway">
        <nav role="navigation">
            <div id="menuToggle">
                <input type="checkbox" v-model="isActive"/>

                <span></span>
                <span></span>
                <span></span>

                <ul id="menu">
                    <div class="singleItem" @click="mainPage" v-on-clickaway="clickAway">
                        <p>Pealeht</p>
                    </div>
                    <div class="singleItem" @click="$router.push('/voiste-karjaar')" v-on-clickaway="clickAway">
                        <p>Võiste II karjäär</p>
                    </div>
                    <div class="singleItem" @click="$router.push('/meist')" v-on-clickaway="clickAway">
                        <p>Meist</p>
                    </div>
                    <div class="singleItem" @click="$router.push('/uudised')" v-on-clickaway="clickAway">
                        <p>Uudised</p>
                    </div>
                    <div class="singleItem" @click="$emit('burgerMenuScroll', 'supportUs')" v-on-clickaway="clickAway">
                        <p>Toeta meid</p>
                    </div>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
    name: 'burgerMenu',
    mixins: [ clickaway ],
    data() {
        return {
            isActive: false,
        }
    },
    methods: {
        mainPage() {
            scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            this.$router.push('/')
        },
        clickAway() {
            if(this.isActive) {
                this.isActive = false
            }
        },
    }
}
</script>

<style scoped>
#menuToggle {
  display: block;
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}
#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -6px;
  cursor: pointer;
  opacity: 0;
  z-index: 2; 
}
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  -webkit-transform-origin: 4px 0px;
      -ms-transform-origin: 4px 0px;
          transform-origin: 4px 0px;
  -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  -o-transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
#menuToggle span:first-child
{
  -webkit-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
#menuToggle span:nth-last-child(2)
{
  -webkit-transform-origin: 0% 100%;
      -ms-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}
#menuToggle input:checked ~ span
{
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
      -ms-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: #BEC4CD;;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
      -ms-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}
#menuToggle input:checked ~ span:nth-last-child(2)
{
  -webkit-transform: rotate(-45deg) translate(0, -1px);
      -ms-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}
#menu
{
  position: absolute;
  top: 39px;
  right: -274px;
  width: 300px;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
  z-index: 30000;
  background: rgb(251, 251, 251);
  border-left: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  border-radius: 0px 0px 0px 10px;
  /* -webkit-box-shadow: -4px 5px 3px 0px rgba(0, 0, 0, 0.157);
          box-shadow: -4px 5px 3px 0px rgba(0, 0, 0, 0.157); */
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  -webkit-transform-origin: 0% 0%;
      -ms-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
  -webkit-transform: translate(125%, 0);
      -ms-transform: translate(125%, 0);
          transform: translate(125%, 0);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  -o-transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
#menuToggle input:checked ~ ul {
  -webkit-transform: none;
      -ms-transform: none;
          transform: translate(-79%, 0);
}
.singleItem {
    cursor: pointer;
    border-bottom: 1px solid rgb(224, 224, 224);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}
.singleItem:last-child {
    border: none;
}
.singleItem p {
    margin: 0;
    color: rgb(58, 82, 85);
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
}
.singleItem:hover {
    background: rgb(242, 242, 242);
}
.singleItem:hover p {
    color: rgb(73, 112, 117);
}
@media (max-width: 400px) {
    #menuToggle {
        right: 10px;
    }
}
</style>
