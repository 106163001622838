<template>
    <div class="wrapper">
        <div class="firstSection">
            <div class="singleSection" @click="changeRoute('/meist')">
                <p>MTÜ</p>
                <h2>Tahkuranna Maastikukaitse</h2>
                <img 
                    class="logoImg"
                    :src="require('@/assets/logo/mainLogo.svg')" 
                    alt=""
                >
                <span class="elementFooter">
                    <span id="first">Loe meist</span>
                    <img 
                        class="arrow"
                        :src="require('@/assets/icons/whiteArrow.svg')" 
                        alt=""
                    >
                </span>
            </div>
            <div class="singleSection" @click="$emit('scroll', 'supportUs')">
                <img 
                    class="image"
                    :src="require('@/assets/images/bannerImageLocal.jpg')" 
                    alt=""
                >
                <p>Toeta</p>
                <h2>MTÜ toimib ainult tänu heade inimeste annetustele</h2>
                <span class="elementFooter">
                    <span>Toeta meid</span>
                    <img 
                        class="arrow"
                        :src="require('@/assets/icons/arrow.svg')" 
                        alt=""
                    >
                </span>
            </div>
            <div class="singleSection" @click="changeRoute('/liikmeks')">
                <img 
                    class="image"
                    :src="require('@/assets/images/bannerImage.jpg')" 
                    alt=""
                >
                <p>Tegutse</p>
                <h2>Ootame enda ridadesse aktiivseid uusi liikmeid</h2>
                <span class="elementFooter">
                    <span>Astu liikmeks</span>
                    <img 
                        class="arrow"
                        :src="require('@/assets/icons/arrow.svg')" 
                        alt=""
                    >
                </span>
            </div>
            <div class="singleSection" @click="changeRoute('/voiste-karjaar')">
                <img 
                    class="image"
                    :src="require('@/assets/images/drone/johan-raisi kinnistu.jpg')" 
                    alt=""
                >
                <p>Kaitse</p>
                <h2>Seisame vastu Võiste teise liivakarjääri loomisele</h2>
                <span class="elementFooter">
                    <span>Loe lisaks</span>
                    <img 
                        class="arrow"
                        :src="require('@/assets/icons/arrow.svg')" 
                        alt=""
                    >
                </span>
            </div>
        </div>

        <div class="mobileSection">
            <ssr-carousel 
                class="carousel"
                :showArrows="false" 
                :slides-per-page="null"
                peek-right="1%"
                peek-left="4%"
                :loop="false"
                :peek-gutter="false"
            >
                <div class="singleSection" @click="changeRoute('/meist')">
                    <p>MTÜ</p>
                    <h2>Tahkuranna Maastikukaitse</h2>
                    <img 
                        class="logoImg"
                        :src="require('@/assets/logo/mainLogo.svg')" 
                        alt=""
                    >
                    <span class="elementFooter">
                        <span id="first">Loe meist</span>
                        <img 
                            class="arrow"
                            :src="require('@/assets/icons/whiteArrow.svg')" 
                            alt=""
                        >
                    </span>
                </div>
                <div class="singleSection" @click="$emit('scroll', 'supportUs')">
                    <img 
                        class="image"
                        :src="require('@/assets/images/bannerImageLocal.jpg')" 
                        alt=""
                    >
                    <p>Toeta</p>
                    <h2>MTÜ toimib ainult tänu heade inimeste annetustele</h2>
                    <span class="elementFooter">
                        <span>Toeta meid</span>
                        <img 
                            class="arrow"
                            :src="require('@/assets/icons/arrow.svg')" 
                            alt=""
                        >
                    </span>
                </div>
                <div class="singleSection" @click="changeRoute('/liikmeks')">
                    <img 
                        class="image"
                        :src="require('@/assets/images/bannerImage.jpg')" 
                        alt=""
                    >
                    <p>Tegutse</p>
                    <h2>Ootame enda ridadesse aktiivseid uusi liikmeid</h2>
                    <span class="elementFooter">
                        <span>Astu liikmeks</span>
                        <img 
                            class="arrow"
                            :src="require('@/assets/icons/arrow.svg')" 
                            alt=""
                        >
                    </span>
                </div>
                <div class="singleSection" @click="changeRoute('/voiste-karjaar')">
                    <img 
                        class="image"
                        :src="require('@/assets/images/drone/johan-raisi kinnistu.jpg')" 
                        alt=""
                    >
                    <p>Kaitse</p>
                    <h2>Seisame vastu Võiste teise liivakarjääri loomisele</h2>
                    <span class="elementFooter">
                        <span>Loe lisaks</span>
                        <img 
                            class="arrow"
                            :src="require('@/assets/icons/arrow.svg')" 
                            alt=""
                        >
                    </span>
                </div>
            </ssr-carousel>
        </div>

        <div class="secoundSection">
            <div class="singlePost" id="leftPost" @click="openLink('ekspress')">
                <div class="shareLink">
                    <div class="imageBottom">
                        <img 
                            :src="require('@/assets/images/eestiekspress.jpeg')" 
                            alt=""
                        >
                    </div>
                    <div class="linkFooter">
                        <p class="origin">EKSPRESS.DELFI.EE</p>
                        <p class="title">Kohalikud võitlevad karjääri ja keskonnaameti vastu: "Kaitsealusel maal jalutada ei tohi, aga kõrvale hiiglasliku augu kaevata võib?"</p>
                    </div>
                </div>
            </div>
            <div class="rightWrapper">
                <div class="singlePost" @click="openLink('ekspress2')">
                    <div class="imageBottom">
                        <img 
                            :src="require('@/assets/images/ekspress2.jpg')" 
                            alt=""
                        >
                    </div>
                    <div class="linkFooter">
                        <p class="origin">EKSPRESS.DELFI.EE</p>
                        <p class="title">Huvilisi rohkem, kui saali mahub: Pärnumaale karjääri kaevata lubavad ametnikud andsid külarahvale aru</p>
                    </div>
                </div>
                <div class="singlePost" @click="openLink('postimees')">
                    <div class="imageBottom">
                        <img 
                            :src="require('@/assets/images/postimees.jpg')" 
                            alt=""
                        >
                    </div>
                    <div class="linkFooter">
                        <p class="origin">PARNU.POSTIMEES.EE</p>
                        <p class="title">Võiste uue liivakarjääri juures kiskus kismaks</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ssrCarousel from 'vue-ssr-carousel'
import 'vue-ssr-carousel/index.css'

export default {
    name: 'contentSections',
    components: {
        ssrCarousel,
    },
    data() {
        return {
        }
    },
    methods: {
        changeRoute(destination) {
            this.$gtag('event', 'Navigeerimine', {
                'Esilehe_sektorid': 'Navigeerimise kaart',
                'value': 4
            });
            scrollTo({
                top: 0,
                behavior: 'smooth'
            })
            setTimeout(() => {
                this.$router.push(destination)
            }, 100)
        },

        openLink(source) {
            this.$gtag('event', 'Navigeerimine', {
                'Esilehe_sektorid': 'Uudise artikkel',
                'value': 4
            });
            if(source == 'postimees') {
                window.open('https://parnu.postimees.ee/7728611/voiste-uue-liivakarjaari-juures-kiskus-kismaks', '_blank').focus()
            }
            if(source == 'ekspress') {
                window.open('https://ekspress.delfi.ee/artikkel/120047900/jalle-salastatud-maaomanik-ei-tea-miks-kohalikud-on-tema-karjaariplaanide-vastu-sest-keskkonnaamet-on-vastused-salastanud?fbclid=IwAR0SqT7o37txNDO07qix12UJ1nGYEjvLLmAu7CjOjGk7ongNIA-3SVs2Mp0', '_blank').focus()
            }
            if(source == 'ekspress2') {
                window.open('https://ekspress.delfi.ee/artikkel/120098374/reportaaz-huvilisi-rohkem-kui-saali-mahub-parnumaale-karjaari-kaevata-lubavad-ametnikud-andsid-kularahvale-aru', '_blank').focus()
            }
            if(source == 'arutelu-esitlus') {
                window.open("/files/Avalik-arutelu-esitlus.pdf", '_blank');
            }
        }
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

.wrapper {
    padding-top: 75px;
    padding-bottom: 125px;
    background: #F4EDE4;
    -webkit-box-shadow: inset 0px -2px 4px -2px rgba(0,0,0,0.3);
    box-shadow: inset 0px -2px 4px -2px rgba(0,0,0,0.3);
}
.mobileSection {
    display: none;
    padding-top: 0px;
}
.carousel {
    width: 100%;
    padding-top: 10px;
    margin-top: 0px;
}
.firstSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    width: 1300px;
    margin: 0px auto;
}
.singleSection {
    position: relative;
    width: 285px;
    height: 420px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    background: white;
    overflow: hidden;
}
.singleSection:hover {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    cursor: pointer;
}
.singleSection:first-child {
    background: rgb(58, 82, 85);
    border: 3px solid rgb(255, 255, 255);
}
.image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    margin: auto;
    width: 100%;
    height: 205px;
}
.singleSection p {
    position: absolute;
    width: 87%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    right: 0;
    top: 30px;
    bottom: 0px;
    margin: auto;
    color: rgba(0, 0, 0, 0.555);
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
}
.singleSection h2 {
    position: absolute;
    width: 87%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    right: 0;
    top: 150px;
    bottom: 0;
    margin: auto;
    color: rgb(50, 50, 50);
    font-size: 24px;
    font-family: 'DM sans', sans-serif;
}
.singleSection:first-child .logoImg{
    position: absolute;
    left: 0;
    right: 0;
    top: 45px;
    bottom: 0;
    margin: auto;
    width: 87%;
}
.singleSection:first-child p {
    position: absolute;
    width: 87%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    right: 0;
    top: 0;
    bottom: 215px;
    margin: auto;
    color: rgba(255, 255, 255, 0.747);
    font-size: 14px;
    font-family: 'DM sans', sans-serif;
}
.singleSection:first-child h2 {
    position: absolute;
    width: 87%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    right: 0;
    top: 0;
    bottom: 130px;
    margin: auto;
    color: white;
    font-size: 24px;
    font-family: 'DM sans', sans-serif;
}
.elementFooter {
    width: 87%;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 1px solid rgb(230,230,230);
    padding-top: 15px;
}
.elementFooter span {
    font-size: 20px;
    color: #5CA47B;
    font-weight: bold;
    font-family: 'DM sans', sans-serif;
}
#first {
    color: white;
}
.secoundSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 1260px;
    margin: 0 auto;
    margin-top: 25px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.rightWrapper {
    width: 48.5%;
}
.singlePost {
    margin-top: 25px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    border-top: none;
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.singlePost:hover {
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
}
#leftPost {
    width: 48.5%;
}
.imageBottom img {
    width: 250px;
    height: 200px;
    -o-object-fit:cover;
       object-fit:cover;
    border-radius: 20px;
    border: 10px solid #ffffff;
}
#leftPost img {
    width: 100%;
    height: 300px;
}
.origin {
    margin: 0;
    font-size: .9rem;
    color: #a5a5a5;
    padding-left: 15px;
    padding-top: 3px;
}
.title {
    margin: 0;
    padding: 0 15px 15px 15px;
    font-weight: bold;
    font-size: 1.2rem;
}
.rightWrapper .linkFooter .origin {
    margin-top: 10px;
    margin-bottom: 12px;
}

@media (max-width: 1300px) {
    .firstSection {
        width: 700px;
    }
    .singleSection {
        margin-bottom: 50px;
    }
    .secoundSection {
        width: 700px;
    }
    .rightWrapper {
        width: 100%;
    }
    #leftPost {
        width: 100%;
    }
}
@media (max-width: 700px) {
    .firstSection {
        width: 285px;
        margin-bottom: -20px;
        display: none;
    }
    .mobileSection {
        margin-top: -35px;
        margin-bottom: -20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .singleSection {
        width: 270px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .singleSection:first-child {
        margin-right: 20px;
        margin-left: 20px;
    }
    .secoundSection {
        width: 90%;
    }
    .singlePost {
        display: initial;
        margin-bottom: 25px;
    }
    .rightWrapper .singlePost {
        margin-bottom: 250px;
    }
    .rightWrapper img {
        width: 100%;
        height: 300px;
    }
    .rightWrapper .linkFooter .origin {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .rightWrapper .linkFooter {
        margin-bottom: 25px;
    }
    .wrapper {
        padding-top: 50px;
        padding-bottom: 30px;
    }
}
</style>
