<template>
    <div class="componentWrapper">
        <div class="bar" @click="$router.push('/')">
            <img 
                class="illustrationBird"
                :src="require('@/assets/illustrations/bird.svg')" 
                alt=""
            >
            <h3 class="nameTitle">Tahkuranna Maastikukaitse</h3>
        </div>
        <h2>Astu MTÜ liikmeks</h2>
        <p class="titleText">Kõigil kes tunnevad soovi anda enda panus Tahkuranna maastiku kaitseks, on võimalus astuda MTÜ Tahkuranna Maastikukaitse liikmeks.</p>
        <div class="wrapper">
            <div class="how">
                <h3>Liimeks astumine</h3>
                <p><b>1)</b> Täida avaldus arvutis ning allkirjasta see digitaalselt</p>
                <p><b>2)</b> Saada allkirjastatud avaldus MTÜ emaili aadressile <br> (tahkurannamaastikukaitse@gmail.com)</p>
                <h5>või</h5>
                <p><b>1)</b> Prindi avaldus välja, täida ja allkirjasta käsitsi</p>
                <p><b>2)</b> Too allkirjastatud avaldus Tahku Taresse</p>
                <div class="imageGallery">
                    <img class="image" v-for="(image, i) in images" :src="image" :key="i" @click="index = i">
                </div>
                <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
                <button @click="download()">Lae alla liitumise avaldus</button>
                <a href="/files/liitumise-avaldus.docx" download id="download" hidden></a>
            </div>
            <div class="contact">
                <h3>MTÜ Tahkuranna Maastikukaitse</h3>
                <p>Pärnu maakond, Häädemeeste vald, Leina küla, Kaasiku, 86504</p>
                <p>Reg. 511561561</p>
                <p>EE516510101516510 (LHV)</p>
                <p class="email">tahkurannamaastikukaitse@gmail.com</p>
                <button @click="openPdf()">Ava MTÜ Põhikiri</button>
            </div>
        </div>
    </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
    name: 'joinPage',
    components: {
        VueGallerySlideshow
    },
    data() {
        return {
            images: [
                require('@/assets/documents/avaldusDocJPG.jpg'),
            ],
            index: null
        }
    },
    methods: {
        download() {
            document.getElementById('download').click();
        },

        openPdf() {
            window.open("/files/MTÜ-Tahkuranna-Maastikukaitse-põhikiri.pdf", '_blank');
        }
    },

    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Astu liikmeks',
        })
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.componentWrapper {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
}
.bar {
    height: 57px;
    width: 100%;
    background: rgb(58, 82, 85);
    position: absolute;
    top: 0;
}
.illustrationBird {
    position: absolute;
    height: 40px;
    top: 9px;
    left: 15px;
}
.nameTitle {
    color: #BEC4CD;
    position: absolute;
    top: 21px;
    left: 60px;
    bottom: 0;
    margin: auto;
    font-size: 1.1rem;
    font-family: 'Quicksand', sans-serif;
}
.nameTitle:hover {
    color: #dce3ee;
    cursor: default;
}
h2 {
    text-align: center;
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: 'Bree Serif', serif;
}
p {
    color: rgb(90, 90, 90);
    font-size: 1.1rem;
}
.titleText {
    text-align: center;
    width: 700px;
    margin: 0 auto;
}
.wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 1100px;
    margin: 0 auto;
}
.how {
    width: 60%;
    margin: 0 auto;
    margin-top: 50px;
}
.how p {
    margin: 0;
    margin-bottom: 10px;
    color: rgb(59, 59, 59);
    font-size: .98rem;
}
.contact {
    width: 40%;
    margin: 0 auto;
    margin-top: 50px;
}
.contact p {
    margin: 0;
    font-size: .98rem;
    margin-bottom: 10px;
}
h3 {
    color: rgb(73, 73, 73);
}
h5 {
    margin: 0;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}
button {
    position: relative;
    top: 0;
    margin-top: 10px;
    z-index: 100;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgb(26, 137, 189);
    border-radius: 20px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
button:hover {
    position: relative;
    top: -5px;
    background: rgb(41, 162, 218);
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.image {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 275px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.4);
}
.image:hover {
    -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
}
@media (max-width: 1100px) {
    .wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
    }
    .how {
        padding-left: 10px;
    }
    .contact {
        padding-right: 10px;
    }
}
@media (max-width: 992px) {
    .componentWrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
@media (max-width: 750px) {
    .titleText {
        width: 90%;
    }
    .how {
        width: 100%;
        padding-left: 50px;
    }
    .contact {
        width: 100%;
        padding-left: 50px;
    }
}
@media (max-width: 450px) {
    .componentWrapper {
        padding-top: 55px;
        padding-bottom: 50px;
    }
    h2 {
        margin-bottom: 10px;
    }
    .how {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .contact {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}
</style>
