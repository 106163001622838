<template>
<div>
    <header class="main-headerT">
        <div class="container">
            <nav class="navbar navbar-expand-lg main-nav px-0">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-bar icon-bar-1"></span>
                    <span class="icon-bar icon-bar-2"></span>
                    <span class="icon-bar icon-bar-3"></span>
                </button>
                <img 
                    class="logoImg"
                    @click="changePage('home')"
                    :src="require('@/assets/logo/mainLogo.svg')" 
                    alt=""
                >
                <div class="collapse navbar-collapse" id="mainMenu">
                    <ul class="navbar-nav ml-auto f1">
                        <li>
                            <a @click="changePage('home')">Pealeht</a>
                        </li>
                        <li>
                            <a @click="changePage('about')">Meist</a>
                        </li>
                        <li>
                            <a @click="changePage('news')">Uudised</a>
                        </li>
                        <li>
                            <a @click="changePage('voisteII')">Võiste II karjäär</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
    <burgerMenu class="burgerMenu" @burgerMenuScroll='burgerMenuScroll' />
</div>
</template>

<script>
import burgerMenu from '@/components/menus/burgerMenu.vue'

export default {
    name: 'mainMenu',
    components: {
        burgerMenu
    },
    methods: {
        changePage(page) {
            this.$gtag('event', 'Navigeerimine', {
                'Menüü': 'Staatiline peamenüü',
                'value': 4
            });
            if(page == 'home') {
                if(this.$route.name != 'home') {
                    scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                    this.$router.push('/')
                } else {
                    scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                }
            }
            if(page == 'contact') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                this.$router.push('/kontakteeru')
            }
            if(page == 'news') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                this.$router.push('/uudised')
            }
            if(page == 'voisteII') {
                scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
                this.$router.push('/voiste-karjaar')
            }
            if(page == 'about') {
                scrollTo({
                    top: 0,
                })
                this.$router.push('/meist')
            }
        },

        scroll(object) {
            if(object == 'project') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'project')
                    }, 100);
                } else {
                    this.$emit('scroll', 'project')
                }
            }
            if(object == 'about') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'about')
                    }, 100);
                } else {
                    this.$emit('scroll', 'about')
                }
            }
        },

        burgerMenuScroll(object) {
            if(object == 'project') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'project')
                    }, 100);
                } else {
                    this.$emit('scroll', 'project')
                }
            }
            if(object == 'about') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'about')
                    }, 100);
                } else {
                    this.$emit('scroll', 'about')
                }
            }
            if(object == 'supportUs') {
                if(this.$route.name != 'home') {
                    this.$router.push('/')
                    setTimeout(() => {
                        this.$emit('scroll', 'supportUs')
                    }, 100);
                } else {
                    this.$emit('scroll', 'supportUs')
                }
            }
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

/* main-header start */
[data-target="#mainMenu"] {
  position: relative;
  z-index: 999;
}
.illustrationBird {
    position: absolute;
    height: 45px;
    top: 13px;
    right: 300px;
}
.logoImg {
    width: 70px;
    margin-right: 25px;
    cursor: pointer;
}
.name {
    color: #BEC4CD;
    position: absolute;
    top: 25px;
    right: 30px;
    bottom: 0;
    margin: auto;
    font-size: 1.2rem;
    font-family: 'Quicksand', sans-serif;
}
.name:hover {
    color: #dce3ee;
    cursor: default;
}
#mainMenu li > a {
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: bold;
  position: relative;
  z-index: 1;
  text-decoration: none;
  font-family: 'DM sans', sans-serif;
}

.main-header.fixed-nav #mainMenu li > a {
  color: #fff;
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin-right: 30px;
}

#mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1px;
  background: #fff;
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

#mainMenu li > a:hover::before,
#mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li > a::before {
  background: #000;
}

.main-header {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 99;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.main-headerT {
  position: absolute;
  padding-top: 10px;
  top: 0px;
  left: 0;
  z-index: 99;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.main-header.vue-fixed-header {
    position: fixed;
    top: -400px;
    left: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.main-header {
    margin: 0;
    background: rgb(58, 82, 85);
    border-bottom: 1px solid rgb(61, 87, 90);
    padding-top: 10px;
    padding-bottom: 20px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.main-headerT{
    margin: 0;
    background: rgb(58, 82, 85);
    padding-bottom: 25px;
}
.main-header.vue-fixed-header--isFixed {
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-box-shadow: 0px 2px 3px -2px rgba(0,0,0,0.5);
    box-shadow: 0px 2px 5px -2px rgba(0,0,0,0.5);
}
.main-header.fixed-nav {
  top: 0;
  background: #fff;
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.navbar-brand {
  color: #fff;
}
.main-header .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
.main-headerT .navbar-brand img {
  max-width: 40px;
  margin-right: 10px;
  -webkit-animation: fadeInLeft 0.4s both 0.4s;
          animation: fadeInLeft 0.4s both 0.4s;
}
a:hover {
    cursor: pointer;
}
.burgerMenu {
    display: none;
}
@media (max-width: 991px) {
    .main-headerT {
        display: none;
    }
    .fixedBurgerMenu {
        top: 0px;
    }
    .illustrationBird {
        height: 40px;
        top: 7px;
        left: 15px;
    }
    .name {
        top: 16px;
        left: 60px;
        bottom: 0;
        font-size: 1.2rem;
    }
}
@media (max-width: 400px) {
    .illustrationBird {
        height: 40px;
        top: 7px;
        left: 7px;
    }
    .name {
        top: 16px;
        left: 52px;
        font-size: 1.1rem;
    }
}
</style>
