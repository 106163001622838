<template>
    <div class="componentWrapper">
        <mainBanner @scroll='scrollToObject'/>
        <sections @scroll='scrollToObject' />
        <supportSection id="supportUs" />  
    </div>
</template>

<script>
import mainBanner from '@/components/home/mainBanner.vue'
import sections from '@/components/home/sections.vue'
import supportSection from '@/components/home/supportSection.vue'

export default {
    name: 'homePage',
    components: {
        mainBanner,
        sections,
        supportSection
    },
    props: {
        scroll: {},
        isActive: {}
    },
    watch: {
        scroll: {
            immediate: true, 
            handler (val) {
                if(val != '' && val != 'supportUs') {
                    this.scrollTo = val
                    this.activeScrollTo = ''
                }
                if(val == 'supportUs') {
                    this.activeScrollTo = ''
                    this.scrollTo = 'supportUs'
                    this.scrollToObject()
                }
            }
        },
        isActive: {
            immediate: true, 
            handler (val) {
                if(val == true) {
                    if(this.scrollTo != 'supportUs') {
                        this.activeScrollTo = this.scrollTo
                        this.scrollTo = ''
                    } else {
                        this.scrollTo = ''
                        this.scrollToObject()
                    }
                }
            }
        }
    },
    data() {
        return {
            scrollTo: '',
            activeScrollTo: ''
        }
    },
    methods: {
        scrollToObject() {
            var el = document.querySelector(`#supportUs`);
            window.scroll({top: el.offsetTop - 40, behavior: 'smooth'});
        },
    },

    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Pealeht',
        })
    }
}
</script>

<style scoped>

</style>
